/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, take } from 'rxjs/operators';
import { matSnackBarConfig } from '../configs/mat-snack-bar-config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../services/auth.service';
import { AuthType } from '../enums/authType.enum';
import { ScreenLoaderService } from '../services/screen-loader.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  private totalRequests = 0;
  refreshTokenTimerRef: any = null;

  constructor(
    private matSnackBar: MatSnackBar,
    private authService: AuthService,
    private screenLoaderService: ScreenLoaderService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + accessToken,
      'X-Auth-type': this.authService.authType === AuthType.contiinex ? 'contiinex' : 'okta',
      'accept': 'application/json, text/plain, */*'
    });
  
    request = request.clone({ headers });

    this.totalRequests++;
    this.screenLoaderService.show();

    return next.handle(request).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          const resBody = event.body;

          if (!resBody?.success && (resBody?.message || resBody?.title)) {
            this.matSnackBar.open(resBody?.message || resBody?.title, '', matSnackBarConfig);
          }
        }  

        if (accessToken) {
          if (this.refreshTokenTimerRef) {
            clearTimeout(this.refreshTokenTimerRef);
          }

          this.refreshTokenTimerRef = setTimeout(() => {
            this.authService.refreshToken().pipe(take(1)).subscribe();
          }, 1000 * 60 * 60);
        }    

        return event;
      }),
      finalize(() => {
        this.totalRequests--;

        if (this.totalRequests == 0) {
          this.screenLoaderService.hide();
        }
      }),
      catchError((err) => {
        if (err.status === 401) {
          this.authService.logout();
        }

        if (err?.error?.message) {
          this.matSnackBar.open(err?.error?.message, '', matSnackBarConfig);
        }

        return throwError(() => new Error(err?.error?.message));
      }),
    );
  }
}
