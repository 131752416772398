import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { rolePermissionConfig } from 'src/@shared/configs/role-permission-config';
import { nexLoginGuard } from 'src/@shared/guards/nex-login.guard';
import { roleGuard } from 'src/@shared/guards/role.guard';
import { unAuthGuard } from 'src/@shared/guards/un-auth.guard';

const loginUrl = '/auth/nex-login';

const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./main-layout/main-layout.component').then((c) => c.MainLayoutComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./main-layout/home/home.component').then((c) => c.HomeComponent),
      },
      {
        path: 'products',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'products/auditnex',
          },
          {
            path: 'auditnex',
            loadComponent: () => import('./main-layout/products/auditnex/auditnex.component').then((c) => c.AuditnexComponent),
          },
          {
            path: 'comnex',
            loadComponent: () => import('./main-layout/products/comnex/comnex.component').then((c) => c.ComnexComponent),
          },
          {
            path: 'dialernex',
            loadComponent: () => import('./main-layout/products/dialernex/dialernex.component').then((c) => c.DialernexComponent),
          }
        ]
      },
      {
        path: 'company',
        children: [
          {
            path: 'contact-us',
            loadComponent: () => import('./main-layout/company/contact-us/contact-us.component').then((c) => c.ContactUsComponent),
          },
          {
            path: 'privacy-policy',
            loadComponent: () => import('./main-layout/company/privacy-policy/privacy-policy.component').then((c) => c.PrivacyPolicyComponent),
          },
          {
            path: 'security',
            loadComponent: () => import('./main-layout/company/security/security.component').then((c) => c.SecurityComponent),
          },
          {
            path: 'about-us',
            loadComponent: () => import('./main-layout/company/about-us/about-us.component').then((c) => c.AboutUsComponent),
          },
        ]
      },
      {
        path: 'solutions/:section',
        loadComponent: () => import('./main-layout/solutions/solutions.component').then((c) => c.SolutionsComponent),
      },
      {
        path: 'audit-video',
        loadComponent: () => import('./main-layout/demo-video/demo-video.component').then((c) => c.DemoVideoComponent),
      },
      {
        path: 'bot-video',
        loadComponent: () => import('./main-layout/demo-video/demo-video.component').then((c) => c.DemoVideoComponent),
      },
      {
        path: 'notifications',
        canActivate: [roleGuard],
        data: {
          permission: rolePermissionConfig.notifications
        },
        loadComponent: () => import('../@shared/components/nex-page-not-found/nex-page-not-found.component').then((c) => c.NexPageNotFoundComponent),
      },
      {
        path: '404',
        canActivate: [roleGuard],
        data: {
          permission: rolePermissionConfig.notFound
        },
        loadComponent: () => import('../@shared/components/nex-page-not-found/nex-page-not-found.component').then((c) => c.NexPageNotFoundComponent),
      },
    ]
  },
  {
    path: 'auth',
    canActivate: [unAuthGuard],
    loadComponent: () => import('./auth-layout/auth-layout.component').then((c) => c.AuthLayoutComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: loginUrl,
      },
      {
        path: 'nex-login',
        canActivate: [nexLoginGuard],
        loadComponent: () => import('./auth-layout/nex-login/nex-login.component').then((c) => c.NexLoginComponent),
      },
      {
        path: 'forgot-password',
        canActivate: [nexLoginGuard],
        loadComponent: () => import('./auth-layout/forgot-password/forgot-password.component').then((c) => c.ForgotPasswordComponent),
      },
      {
        path: 'reset-password/:token',
        canActivate: [nexLoginGuard],
        loadComponent: () => import('./auth-layout/reset-password/reset-password.component').then((c) => c.ResetPasswordComponent),
      },
    ],
  },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
