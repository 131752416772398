import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HeaderInterceptor } from 'src/@shared/interceptors/header.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NexScreenLoaderComponent } from 'src/@shared/components/nex-screen-loader/nex-screen-loader.component';
@NgModule({ 
    declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        NexScreenLoaderComponent,
    ], 
    providers: [
        {
            provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),        
    ],
})
    
export class AppModule {}
    